import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class SundayBloodySundays extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Sunday Bloody Sundays - Rob Williams</h1>
                <div className="libraryImages">
                    <StaticImage src="../../../images/sundayBloodySundays.jpg" alt="Sanchin"/>
                </div>

                <p>Saturday night and the message reads: "bring old running shoes and hachimaki on Sunday -
                    Graham." </p>

                <p>As I read the note, my stomach ties itself in knots as I remember the last time such a command was
                    issued. Another Do or Die Sunday. I wonder what physical impossibilities Sensei has in mind for us
                    tomorrow.</p>

                <p>Sunday morning I am awake and it would be great to stay in bed. I should get up but my body keeps
                    saying, no don't go. I know I must. It's a love hate thing like an uncontrollable addiction and I
                    must get my karate fix for the day. The words of my wife come to mind, "Bloody Sundays" who do you
                    think is going to look after you when you are old and crippled at 50? She is jealous of my karate,
                    she sees it as the "other woman" and lover in my life against whom she can't compete. I hear my
                    daughters ask their mum, "Is Dad off to training again? Why can't he stay home?".</p>

                <p>A quick breakfast and I am on my way. I tell the family that I will be home early about 4pm straight
                    after training, but they don't take me seriously, they know better.</p>

                <p>I head off on what is a nice relaxing country drive through the D'Aguilar Ranges towards Sensei's
                    place. I wonder what form of physical torment he has in store for us today? My stomach feels like a
                    net full of butterflies as I turn into Sensei's land.</p>

                <p>No time wasted on idle chitchat we are here for a purpose. We're off on a fast jog come run,
                    backwards, sideways, zigzag up, hill down hill, off the road into the bush over logs and creeks. My
                    old ankle injury from past training sessions is starting to rear its head but maybe it's only my
                    brain trying to find a way out for the rest of my body. I forget it. Slipping, sliding, rolling down
                    the hill we head fro what Sensei calls nature's obstacle course.</p>

                <p>Watch, run up, jump back and forth over this suspended log (about knee high) 10 times, hit the
                    ground, roll under, quickly jump up on your feet, run up this fallen tree trunk balancing then jump
                    to that boulder, jump off, roll onto the ground to break your fall, quickly up mae geri, mawashi
                    geri, sokuto geri, ushiro geri, ura-ken, gyaku zuki. Hajime, Go.</p>

                <p>I'm starting to get into this but after the eighth time around I lose count. I don't want to waste
                    energy. It's getting painful and I'm feeling stuffed. Sensei is pushing us faster, stronger, badger,
                    badger, push push, Gambatte stronger, do your best. Yame and we're off again running toward a clump
                    of trees into the shade. Very thoughtful I think, out of the sun, maybe a quick rest? NO WAY, he has
                    other ideas. Kotekitae, arm pounding and shin pounding against these 3 to 4 inch saplings. Now fight
                    the tress, be aware and make them come to life, they're all around you. Elbows, knees, punches and
                    kicks, skin and bark flying lucky some of these trees are paper barks.</p>

                <p>Sensei now directs us to fight each other what a relief, although my new opponent can now strike
                    back. It doesn't hurt so much to punch and kick, at least the human body has some give in it, almost
                    like jelly compared to the trees. Change partners. Now I am with Sensei. I'm pushing myself - kick
                    harder, faster, think and let it flow. It's no use. I'm on the ground more than not but I dare not
                    give in and stay down. I know Sensei has no time for quitters. He is just playing with me like a cat
                    with a mouse. Sensei is playing and I am fighting for my life. I know he'll stop when he's finished
                    with me when he knows I've got no more to give and not before. "Yame" change, "yame" change, "yame"
                    change. Maybe the best part of an hour has past of alternating between tree and flesh. I prefer
                    flesh. </p>

                <p>REST 5 minutes. Off we go again to that creek bed and find a rock. No too small, one this size. I can
                    hardly grasp it. Now throw it to one another and catch it." After several variations of rockwork,
                    hip evasion against incoming missiles of volcanic rock we hit the road again. But at least this time
                    we're heading in the right direction, HOME. The welts, bumps, limps and bruises are starting to
                    sting as I come off my physical and mental high and my body systems enter a damage control mode. As
                    we run back the last 2 kilometres home to Honbu my mind says yes you can do it. Sensei yells, push
                    yourself, but the body is failing. I look around. It is not only me. That last bloody big hill, I
                    can see it looming up in front of us, my heart is racing, and my mouth feels like I've swallowed a
                    box of tissues and my limbs are aching. I can't give up now, no way. I draw my energy from Sensei.
                    The words of a friend come racing back from the past. He trained with Ravey in the early days, left
                    and became a black belt in another style. Maybe he did the smart thing. €œYou guys are crazy", he
                    said. "There's no way I'm going to abuse my body this way.</p>

                <p>GREAT. I can see Honbu and the dam. Even better still we're heading for its cool waters for a swim, I
                    think. No wrong again. Sensei invites us to spend another 30 minutes of BASICS shoulder deep in this
                    murky pond, countless punches and endless kicks pulled out of the ankle deep sludge on the bottom
                    and we're finished.</p>

                <p>FINISHED. 3 hours after we started laid back relaxing with a cold cider, surveying a beautiful view
                    of the Kilcoy ranges from Sensei's front veranda. I feel great. I love my compulsory T.O.G.K.A
                    Instructor's training every fortnight. This is where you pay for the privilege of being one of
                    Sensei Ravey's Dansha. NO PAIN NO GAIN & DEFINITELY NO FREE RIDES.</p>

                <p>I love the satisfaction and sense of achievement that comes with the end of each Sunday session. I
                    wouldn't miss it for the world. Some might call it a form of masochism but we call it Traditional
                    Okinawa Goju-Ryu Karate-do. The Way.</p>

                <p>Maybe next Sunday we'll get to do kata up at the waterfall. It's beautiful up in the mountains.
                    They're not all Do or Die Sundays. After all it's Goju, hard soft.</p>

                <p><strong>Sensei Rob Williams</strong></p>
            </Layout>);
    }
}

export default SundayBloodySundays;
